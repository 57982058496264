$(function () {
    document_ready();
    window_onload();
});

function document_ready() {
    ts = (new Date()).getTime();
    $("body").addClass("ready");

    responsive_init();
    init_event_handlers();

    bind_widgets();
    $(document).ajaxStop(function () {
        bind_widgets();
    });

    scroll_animations_init();

    te = (new Date()).getTime();
    console.log("On-Ready Load Time: ", te - ts);
}

function window_onload() {
    ts = (new Date()).getTime();
    $("body").addClass("win-loaded").removeClass("win-not-loaded");
    $(document).imagesLoaded(function () {
        te = (new Date()).getTime();
        console.log("Images Load Time: ", te - ts);
        setTimeout(function () {
            $("body").addClass("loaded").removeClass("not-loaded");
        }, 10);
    });

    responsive_update();
    lazy_load();

    te = (new Date()).getTime();
    console.log("Window Load Time: ", te - ts);
}

$(window).scroll(function () {
    var $body = $("body");
    if (typeof $body.data("scrollval") === "undefined") {
        $body.data("scrollval", 0);
    }

    scroll_animations();
    lazy_load();

    $body.data("scrollval", $(window).scrollTop());
});

function init_event_handlers() {

    fix_touch_hovers();
    click_touch_init();
    textfield_init();
    tabs_init();
    goto_init();
    toggler_init();
    gmap_load();

    $(document).on('click', '.js-search-toggle', function (e) {
        e.preventDefault();
        $("body").toggleClass("hsearch-active");
        if ($("body").hasClass("hsearch-active")) {
            delay(function(){
                $(".hsearch :text").trigger("focus");
            }, 500);
        }
    });

    $(document).on('click', '*', function (e) {
        if (!$(e.target).closest('.hsearch').length && !$(e.target).closest('.header').length && !$(e.target).closest('.js-search-toggle').length) {
            $("body").removeClass("hsearch-active");
        }
    });

    $(document).on('click', '.js-menu-toggle', function (e) {
        e.preventDefault();
        $("body").toggleClass("mmenu-active");
    });

    $(document).on('click', '*', function (e) {
        if (!$(e.target).closest('.mmenu').length && !$(e.target).closest('.js-menu-toggle').length) {
            $("body").removeClass("mmenu-active");
        }
    });

}

function update_on_sidebar() {
    var $slider = $(".swiper-slider--cards .swiper-container");
    if ($slider.length) {
        $slider[0].swiper.update();
    }
}

function form_process_errors($form, jqXHR) {
    console.log(jqXHR.status, jqXHR.responseJSON);
    var error_text;
    if (jqXHR.status === 401) {
        error_text = 'Access denied.';
    }
    else if (jqXHR.status === 422) {
        var errors = jqXHR.responseJSON;
        var index = 0;
        $.each(errors, function(field_name, field_errors){
            $field = $form.find("[name='" + field_name + "']");
            if (!index) {
                $field.trigger("focus");
            }
            $field.addClass("error");
            $.each(field_errors, function(error_index, error_text) {
                $field.parent().append("<div class='form__error form__error--"+error_index+"'>" + error_text + "</div>");
            });
            index++;
        });
    }
    else {
        error_text = 'Try again.';
    }
    alert(error_text);
}

$(window).on("resize orientationchange", function (e) {
    responsive_update();
    scroll_animations();
    $("body").addClass("no-transition");
    delay(function(){
        $("body").removeClass("no-transition");
    }, 50);
});

function bind_widgets() {
    tel_replace_init();
    img_to_bg();
    placeholder_init();
    fancybox_init();
    validate_init();
    tooltip_init();
    selectbox_init();
    checkbox_plain_init();
    slider_init();
    expand_it_init();
}