/*

Selectboxes

 */

function selectbox_init($o) {

    if (!$().select2) return;

    if (!$("body").hasClass("selectbox-inited")) {

        $(document).on("focus", '.select2-search__field', function(e) { // resolve focus problem in fancybox popup
            e.stopPropagation();
        });

        $("body").addClass("selectbox-inited");
    }

    $.fn.select2.amd.define('select2/data/maximumSelectionLength1',[

    ], function (){
        function MaximumSelectionLength (decorated, $e, options) {
            this.maximumSelectionLength = options.get('maximumSelectionLength1');

            decorated.call(this, $e, options);
        }

        MaximumSelectionLength.prototype.query =
            function (decorated, params, callback) {
                var self = this;

                this.current(function (currentData) {
                    var count = currentData != null ? currentData.length : 0;
                    if (self.maximumSelectionLength1 > 0 &&
                        count >= self.maximumSelectionLength1) {
                        self.trigger('results:message', {
                            message: 'maximumSelected',
                            args: {
                                maximum: self.maximumSelectionLength1
                            }
                        });
                        return;
                    }
                    decorated.call(self, params, callback);
                });
            };

        return MaximumSelectionLength;
    });

    $.fn.select2.amd.require([
        "select2/utils",
        "select2/dropdown",
        "select2/dropdown/attachContainer",
        "select2/dropdown/search",
        "select2/selection/search",
        "select2/dropdown/search",
        "select2/selection/base",
        "select2/selection/single",
        "select2/selection/multiple",
        "select2/core",
        "select2/selection/allowClear"
    ], function (Utils, DropdownAdapter, AttachContainer, DropdownSearch, SelectionSearch, DropdownSearch, BaseSelection, SingleSelection, MultipleSelection, Select2Core, AllowClear) {

        SingleSelection.prototype.render = function () {
            var $selection = SingleSelection.__super__.render.call(this);

            $selection.addClass('select2-selection--single');

            $selection.html(
                '<span class="select2-placeholder"><span class="select2-placeholder__inner">' + this.options.get('placeholder') +'</span></span>' +
                '<span class="select2-selection__rendered"></span>' +
                '<span class="select2-selection__arrow" role="presentation">' +
                '<b role="presentation"></b>' +
                '</span>'
            );

            return $selection;
        };

        MultipleSelection.prototype.render = function () {
            var $selection = MultipleSelection.__super__.render.call(this);

            $selection.addClass('select2-selection--multiple');

            $selection.html(
                '<span class="select2-placeholder"><span class="select2-placeholder__inner">' + this.options.get('placeholder') +'</span></span>' +
                '<ul class="select2-selection__rendered"></ul>' +
                '<span class="select2-selection__arrow" role="presentation">' +
                '<b role="presentation"></b>' +
                '</span>'
            );
            $selection.prop("tabindex", this.$element.data('old-tabindex'));

            return $selection;
        };

        MultipleSelection.prototype.selectionContainer = function () {
            var $container = $(
                '<li class="select2-selection__choice">' +
                '<span class="select2-selection__choice__remove" role="presentation">' +
                '<svg class="icon icon--cross-bold">' +
                '<use xlink:href="#icon-cross-bold"></use>' +
                '</svg>' +
                '</span>' +
                '</li>'
            );

            return $container;
        };

        DropdownSearch.prototype.render = function (decorated) {
            var $rendered = decorated.call(this);

            var $search = $(
                '<span class="select2-search select2-search--dropdown">' +
                '<div class="textfield-wrapper">' +
                '<input class="select2-search__field textfield" type="search" tabindex="-1"' +
                ' autocomplete="off" autocorrect="off" autocapitalize="off"' +
                ' spellcheck="false" role="textbox" placeholder=" " />' +
                '<label class="textfield-placeholder" for="">Поиск по названию</label>' +
                '<span class="textfield-reset"></span>' +
                '</div>' +
                '</span>'
            );

            this.$searchContainer = $search;
            this.$search = $search.find('input');

            $rendered.prepend($search);

            return $rendered;
        };

        SelectionSearch.prototype._transferTabIndex = function (decorated) {
            // remove tabindex transfer
        };

        SelectionSearch.prototype.searchRemoveChoice = function (decorated, item) {

            this.trigger('unselect', {
                data: item
            });

            this.$search.val('');
            this.handleSearch();
        };

        AllowClear.prototype.update = function (decorated, data) {
            decorated.call(this, data);

            this.$selection.removeClass("select2-selection--with-clear");
            if (this.$selection.find('.select2-selection__placeholder').length > 0 ||
                data.length === 0) {
                return;
            }

            var $remove = $(
                '<span class="select2-selection__clear">' +
                '&times;' +
                '</span>'
            );
            $remove.data('data', data);

            this.$selection.addClass("select2-selection--with-clear").find('.select2-selection__rendered').prepend($remove);
        };

        if (typeof $o === "undefined") $o = $('select.selectbox');
        $o.not('.selectbox-inited').each(function () {
            var options = {
                tags: false,
                theme: "default",
                dropdownAutoWidth: true,
                dropdownParent: $('.wrap')
            };
            if ($(this).data("selectbox-show-values"))
            {
                options["templateSelection"] = function(state) {
                    if (!state.id) {
                        return state.text;
                    }
                    var $state = state.element.value;
                    return $state;
                };
            }
            if (!$(this).data("selectbox-search"))
            {
                options["minimumResultsForSearch"] = Infinity;
            }
            if ($(this).data("selectbox-autowidth"))
            {
                options["width"] = "auto";
            }
            if ($(this).data("selectbox-tags-source"))
            {
                options["dropdownParent"] = $(this).closest(".selectbox-tags__destination").find("select").parent();
                options["theme"] += " select2-container--tags-destination";
            }
            else if ($(this).data("selectbox-tags"))
            {
                var $towrap = $(this);
                if ($(this).closest(".textfield-wrapper").length)
                {
                    $towrap = $(this).closest(".textfield-wrapper");
                }
                var $cloned_select_html = $towrap.clone();
                $towrap.wrap("<div class='selectbox-tags'>");
                $cloned_select_html.insertAfter($towrap);
                $towrap.wrap("<div class='selectbox-tags__source'>");
                $cloned_select_html.wrap("<div class='selectbox-tags__destination'>");
                options["dropdownParent"] = $towrap;
                var $cloned_select_select = $cloned_select_html;
                if ($cloned_select_html.find("select").length)
                {
                    $cloned_select_select = $cloned_select_html.find("select");
                }
                var $cloned_select_placeholder = $cloned_select_html.find(".textfield-placeholder");
                if ($cloned_select_placeholder.length)
                {
                    $cloned_select_placeholder.html($cloned_select_placeholder.data("tags-label")?$cloned_select_placeholder.data("tags-label"):"");
                }
                $cloned_select_select.attr("data-placeholder", "").removeAttr("data-selectbox-aggregate-min");
                $cloned_select_select.find("option[value!='']").remove();
                $(this).removeAttr("name");
                $(this).data("selectbox-tags-destination", $cloned_select_select);
                $cloned_select_select.data("selectbox-tags-source", $(this));
                if ($cloned_select_select.data("selectbox-tags-source"))
                {
                    selectbox_init($cloned_select_select);
                }
                $(this).on("change", function(){
                    var $dest = $(this).data("selectbox-tags-destination");
                    sync_selects_append($(this)[0], $dest[0]);
                });
                $cloned_select_select.on("change", function(){
                    var $source = $(this).data("selectbox-tags-source");
                    sync_selects($(this)[0], $source[0]);
                });
                options["theme"] += " select2-container--tags-source";
                options["containerCssClass"] = "select2-selection--notags";
                options["dropdownCssClass"] = "select2-dropdown--notags";
            }
            else if ($(this).data("selectbox-tags-create"))
            {
                options["dropdownParent"] = $(this).parent();
                options["tags"] = $(this).data("selectbox-tags-create");
                options["containerCssClass"] = "select2-selection--tags-create";
                options["dropdownCssClass"] = "select2-dropdown--tags-create";
            }
            else if (!$(this).data("selectbox-tags"))
            {
                options["containerCssClass"] = "select2-selection--notags";
                options["dropdownCssClass"] = "select2-dropdown--notags";
            }
            if ($(this).data("selectbox-allow-clear"))
            {
                options["allowClear"] = $(this).data("selectbox-allow-clear");
            }
            if ($(this).data("selectbox-limit"))
            {
                options["maximumSelectionLength"] = $(this).data("selectbox-limit");
            }
            if ($(this).prop("multiple"))
            {
                options["closeOnSelect"] = false;
            }
            /*if ($(this).closest(".fancybox-slide").length)
            {
                options["dropdownParent"] = $(this).closest(".textfield-wrapper");
            }*/
            if ($(this).data("selectbox-dropdown-parent"))
            {
                options["dropdownParent"] = $($(this).data("selectbox-dropdown-parent"));
            }
            if ($(this).data("selectbox-ajax"))
            {
                options["ajax"] = $(this).data("selectbox-ajax");
            }

            if ($(this).closest(".fancybox-slide").length)
            {
                options["theme"] += " select2-container--in-fancybox";
            }
            //$(this).find("option[value='']:empty").remove();
            $(this).on("change", function () {
                if (!$(this).prop("multiple")) {
                    $(this).select2('close');
                }
                $(this).data('select2').$container.toggleClass("select2-container--filled", !!$(this).val().length);
                if ($(this).hasClass("selectbox-change-tip"))
                {
                    var tip = $(this).find("option:selected").data("form-tip");
                    var $tip = $(this).closest(".form__item").find(".form__tip");
                    if (tip)
                    {
                        $tip.html(tip).removeClass("hidden");
                    }
                    else
                    {
                        $tip.html("").addClass("hidden");
                    }
                }
            }).select2(options).addClass("selectbox-inited");
            $(this).data('select2').$container.toggleClass("select2-container--filled", !!$(this).val().length);

            $(this).data('select2').on("results:all", function (params) {
                var $options = this.$dropdown.find(".select2-results__options");
                setTimeout(function(){
                    $options.toggleClass("select2-results__options--scrollbar", $options.hasScrollBar());
                }, 10);
            });

            $(this).on("select2:open", function (e) {
                var _this = this;
                var $options = $(_this).data('select2').$dropdown.find(".select2-results__options");
                $(_this).data('select2').$dropdown.addClass("select2-container--dropdown");
                if ($(this).data("selectbox-dropdown-nowrap"))
                {
                    $(_this).data('select2').$dropdown.addClass("select2-container--dropdown-nowrap");
                }
                setTimeout(function(){
                    $options.toggleClass("select2-results__options--scrollbar", $options.hasScrollBar());
                    if (!$(_this).data('select2').$dropdown.closest(".select2-container--tags-source").length)
                    {
                        if ($(".touchevents").length) forceRedraw($(_this).data('select2').$dropdown[0]);
                        else $(_this).data('select2').$dropdown.css("transform", "translateZ(0)");
                    }
                }, 1);
            });

            $(this).on("select2:unselecting", function (e) {
                var select2 = $(this).data('select2');
                var opts = select2.options;
                opts.set('disabled', true);
                setTimeout(function() {
                    opts.set('disabled', false);
                }, 1);
            });

            $(this).on("select2:select", function (e) {
                var _this = this;
                setTimeout(function() { // Hotfix for correct dropdown position if more than maximumSelectionLength
                    $(window).scrollTop($(window).scrollTop()+1);
                    $(window).scrollTop($(window).scrollTop()-1);
                }, 1);
            });

            $(this).on("select2:select select2:unselect select2:selection-aggregate", function (e) {
                var select2 = $(this).data('select2');
                var opts = select2.options;
                var $rendered = select2.$selection.find(".select2-selection__rendered");
                if ($rendered.filter("span").length) {
                    var current_text = $.trim($rendered.filter("span").text());
                    if (current_text) {
                        $rendered.text(current_text);
                    }
                }

                /* http://stackoverflow.com/a/39787191 */
                var values = [];
                var output = "";
                var agg_min = $(this).data("selectbox-aggregate-min");
                $(this).find("option:selected").each(function(i, selected){
                    values[i] = $(selected).text();
                });
                if (values.length >= agg_min)
                {
                    output = "<li class='select2-selection__choice'>Выбрано "+values.length+"</li>";
                }
                if (output)
                {
                    $rendered.children().not(".select2-selection__clear").remove();
                    $rendered.prepend(output);
                }
            }).trigger("select2:selection-aggregate");

        });
    }, null, true);

}

function sync_selects(el1, el2) {
    if ($(el1).data("change-triggering"))
    {
        return false;
    }
    if (!el1)
    {
        return false;
    }
    else
    {
        var options1 = el1.getElementsByTagName('option');
        var options2 = el2.getElementsByTagName('option');
        for (var i = 0, len = options1.length; i < len; i++)
        {
            var val = options1[i].value;
            $(options2).filter("[value='"+val+"']").prop("selected", options1[i].selected);
        }
        $(el2).data("change-triggering", true).trigger("change").trigger("select2:selection-aggregate").data("change-triggering", false);
    }
}

function sync_selects_append(el1, el2) {
    var change_reverse = $(el1).data("change-reverse");
    if ($(el1).data("change-triggering"))
    {
        return false;
    }
    if (!el1)
    {
        return false;
    }
    else {
        var options1 = el1.getElementsByTagName('option');
        var options2 = el2.getElementsByTagName('option');
        if (change_reverse) {
            options3 = options1;
            options1 = options2;
            options2 = options3;
            el3 = el1;
            el1 = el2;
            el2 = el3;
        }
        for (var i = 0, len = options1.length; i < len; i++) {
            var val = options1[i].value;
            if (!change_reverse) {
                var exist = $(options2).filter("[value='" + val + "']").length;
                if (!exist) {
                    $(el2).append($(options1[i]).clone());
                }
            }
            $(options2).filter("[value='" + val + "']").prop("selected", options1[i].selected);
        }
        $(el2).data("change-triggering", true).trigger("change").trigger("select2:selection-aggregate").data("change-triggering", false);
    }
}

(function ($) {
    $.fn.refreshDataSelect2 = function (data) {
        this.select2('data', data);

        // Update options
        var $select = $(this[0]);
        var options = data.map(function(item) {
            return '<option value="' + item.id + '">' + item.text + '</option>';
        });
        $select.html('<option value=""></option>');
        $select.append(options.join('')).trigger("change");
    };
})(jQuery);


/*

Tooltips

 */

function tooltip_init($o, callback, options) {

    if (!$().tooltipster) return;

    if (!$("body").hasClass("tooltip-inited")) {

        $(document).on("mouseup", ".tooltip-closest-hide", function (e) {
            var $this = $(this);
            var $tooltip = $this.closest(".tooltipster-base");
            if ($tooltip.length)
            {
                $.each($.tooltipster.instances(), function(k, v){
                    if ($tooltip.attr("id") == v.__namespace)
                    {
                        v._$origin.tooltipster("close");
                        return false;
                    }
                });
            }
        });

        $(document).on('touchstart touchmove', '.tooltip-popover-for-mobiles.tooltipster-sidetip', function(e) {
            e.stopPropagation();
            $("html").addClass("tooltip-popover-for-mobiles-opened");
        });

        $(document).on("touchstart", ".tooltip-popover-for-mobiles-opened body", function(e){
            $("html").removeClass("tooltip-popover-for-mobiles-opened");
        });

        $(document).on("keyup", function (e) {
            if (e.keyCode == 27) {
                var instances = $.tooltipster.instances();
                $.each(instances, function(i, instance){
                    instance.close();
                });
            }
        });

        $(window).on("scroll", function (e) {
            var instances = $.tooltipster.instances();
            $.each(instances, function(i, instance){
                if (instance._$origin.tooltipster('option', 'theme')[0].indexOf("tooltipster-popover") < 0)
                {
                    instance.close();
                }
            });
        });

        $("body").addClass("tooltip-inited")
    }

    var options_default = {
        interactive: false,
        interactiveTolerance: 200,
        animationDuration: 200,
        delay: [50, 50],
        distance: 5,
        side: "top",
        maxWidth: 350,
        contentAsHTML: true,
        repositionOnScroll: false,
        updateAnimation: false,
        theme: ["tooltipster-default"],
        functionInit: function (instance, helper) {
            var $origin = $(helper.origin),
                dataOptions = $origin.attr('data-tooltipster');

            if (dataOptions) {
                dataOptions = JSON.parse(dataOptions);
                if (typeof dataOptions["theme"] !== "undefined")
                {
                    dataOptions["theme"] = dataOptions["theme"] + " tooltipster-default";
                }
                $.each(dataOptions, function (name, option) {
                    instance.option(name, option);
                });
            }
            $(this).attr("data-tooltip-text", $(this).attr("title"));
        },
        functionBefore: function (instance, helper) {
            $(helper.origin).addClass("tooltip-active");
            if ($(helper.origin).hasClass("tooltip-popover-for-mobiles"))
            {
                $("html").addClass("tooltip-popover-for-mobiles-opened");
            }
            if ($(helper.origin).data("tooltip-url")) {
                if ($(helper.origin).data('ajax') !== 'cached') {
                    $(helper.origin).tooltipster('content', "Загрузка...");
                    $.ajax({
                        type: 'get',
                        cache: false,
                        url: $(helper.origin).data("tooltip-url"),
                        success: function (data) {
                            $(helper.origin).tooltipster('content', data);
                            $(helper.origin).data('ajax', 'cached');
                            $(instance.elementTooltip()).find(".tooltip-hide").on("click.tooltip-hide", function (e) {
                                e.preventDefault();
                                instance.close();
                            });
                        }
                    });
                }
            }
            else {
                if ($(helper.origin).data("title")) {
                    $(helper.origin).tooltipster('content', $(helper.origin).data("title"));
                }
                else if ($(helper.origin).attr("data-tooltip-text")) {
                    $(helper.origin).tooltipster('content', $(helper.origin).attr("data-tooltip-text"));
                }
            }
            var instances = $.tooltipster.instances();
            $.each(instances, function(i, instance){
                instance.close();
            });
        },
        functionReady: function (instance, helper) {
            if ($(instance.elementOrigin()).filter(".tooltip-disabled").length)
            {
                $(instance.elementTooltip()).addClass("hidden");
            }
            $(instance.elementTooltip()).find(".tooltip-hide").off("click.tooltip-hide").on("click.tooltip-hide", function (e) {
                e.preventDefault();
                instance.close();
            });
            if (typeof $(helper.origin).data("active-index") !== "undefined")
            {
                $(instance.elementTooltip()).find(".menu-list li").eq($(helper.origin).data("active-index")).addClass("active").siblings().removeClass("active");
            }
        },
        functionAfter: function (instance, helper) {
            $(helper.origin).removeClass("tooltip-active");
            if ($(helper.origin).hasClass("tooltip-popover-for-mobiles"))
            {
                $("html").removeClass("tooltip-popover-for-mobiles-opened");
            }
        },
    };

    if (typeof $o === "undefined") $o = $('.tooltip');

    $.extend(options_default, options);

    if ($(".touchevents").length) $.extend(options_default, {trigger: "click"});
    if (typeof callback !== "undefined") $.extend(options_default, {functionAfter: callback});
    $o.not('.tooltip-inited').each(function () {
        var options_current = $.extend({}, options_default);

        if ($(this).hasClass("tooltip-popover"))
        {
            options_current["functionPosition"] = function(instance, helper, position){
                var gridBcr = helper.origin.getBoundingClientRect(),
                    $clone = $(helper.tooltipClone).find('.tooltipster-box'),
                    arrowSize = parseInt($clone.css('margin-left'));
                var left_coord = gridBcr.left;
                var top_coord = position.coord.top;
                var dx = 0;
                var dy = 0;
                if ($(helper.origin).hasClass("tooltip-popover-right"))
                {
                    left_coord = gridBcr.right - $clone.outerWidth();
                }
                if ($(helper.origin).hasClass("tooltip-popover-margin"))
                {
                    dx = 15;
                    dy = -40;
                    if ($(helper.origin).hasClass("tooltip-popover-right"))
                    {
                        dx = -1*dx;
                    }
                    if (position.side == "top")
                    {
                        dy = -1*dy;
                    }
                }
                left_coord += dx;
                top_coord += dy;
                // override these
                position.coord = {
                    // move the tooltip so the arrow overflows the grid
                    left: left_coord - arrowSize,
                    top: top_coord
                };

                return position;
            };
            options_current["side"] = "bottom";
            options_current["trigger"] = "custom";
            if ($(".touchevents").length)
            {
                options_current["triggerOpen"] = {
                    tap: true
                };
                options_current["triggerClose"] = {
                    tap: true,
                    scroll: true
                };
            }
            else
            {
                options_current["triggerOpen"] = {
                    click: true
                };
                options_current["triggerClose"] = {
                    click: true,
                    scroll: true
                };
            }
            options_current["interactive"] = true;
            options_current["viewportAware"] = false;
            options_current["distance"] = 5;
            options_current["theme"] = "tooltipster-popover";
            var $ds = $(this).closest(".dropdown-selection");
            if ($ds.length && $ds.find(".dropdown-selection-content").length)
            {
                $.extend(options_current, {content: $ds.find(".dropdown-selection-content")});
            }
            $ds.on("click", function(e){
                e.stopPropagation();
            });
        }
        if ($(this).hasClass("tooltip-notouch")) {
            options_current["trigger"] = "custom";
            if (!Modernizr.touchevents) {
                options_current["triggerOpen"] = {
                    mouseenter: true,
                    touchstart: false
                };
                options_current["triggerClose"] = {
                    mouseleave: true,
                    originClick: false,
                    touchleave: false
                };
            }
        }
        if ($(this).hasClass("tooltip-popover-for-mobiles"))
        {
            options_current["theme"] = "tooltipster-popover tooltip-popover-for-mobiles";
        }
        if ($(this).data("tooltip-trigger")) $.extend(options_current, {trigger: $(this).data("tooltip-trigger")});
        if ($(this).is("input,textarea") && !$(this).data("tooltip-disable-input-behavior")) {
            $.extend(options_current, {trigger: "custom", autoClose: false});
            $(this).on("focus", function () {
                $(':input.tooltip-inited').not(this).tooltipster('hide');
                $(this).tooltipster('show');
            });
        }
        if ($(this).data("tooltip-url")) $.extend(options_current, {content: "Загрузка..."});
        if (typeof $(this).data("interactive") !== "undefined") $.extend(options_current, {interactive: $(this).data("interactive")});
        $(this).tooltipster(options_current).addClass("tooltip-inited");
    });
}
