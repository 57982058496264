
/*

 Textfields (with reset icon, adaptive placeholders etc)

 */

function textfield_init($o)
{
    if (typeof $o === "undefined") $o = $(".textfield");

    if (!$("body").hasClass("textfield-inited-globally")) {

        $(document).on("keydown keyup focus blur textfield-filled", ".textfield", function (e) {
            var is_filled = !!$(this).val();
            var $placeholder = $(this).nextAll(".textfield-placeholder").first();
            if (e.type === "textfield-filled") { // remove inline attribute value for CSS [value] selector purpose
                if ($(this).val().length) $(this).prop("value", $(this).val());
                $(this).removeAttr("value");
            }
            if ($placeholder.length && $placeholder.attr("data-label-short"))
            {
                if (e.type == "focusin" || e.type == "focusout")
                {
                    var label;
                    if (e.type == "focusin")
                    {
                        label = $placeholder.attr("data-label-short");
                        if (!$placeholder.attr("data-label-long"))
                        {
                            $placeholder.attr("data-label-long", $placeholder.html());
                        }
                    }
                    if (e.type == "focusout" && !is_filled)
                    {
                        if ($placeholder.attr("data-label-long"))
                        {
                            label = $placeholder.attr("data-label-long");
                        }
                    }
                    $placeholder.html(label);
                }
            }
            $(this).toggleClass("filled", is_filled);
        });

        $(".textfield").trigger("textfield-filled");
        $(document).ajaxStop(function () {
            $(".textfield").trigger("textfield-filled");
        });

        $(document).ajaxStop(function () {
            textfield_autosize_init($o);
        });

        $("body").addClass("textfield-inited-globally")
    }

    textfield_autosize_init($o);

    $o.trigger("textfield-filled");

}

function placeholder_init() {
    $(":input[placeholder]").not(".placeholder-inited").placeholder().addClass("placeholder-inited");
}

function textfield_autosize_init($o)
{
    if (typeof $o === "undefined") $o = $(".textfield");
    $o.filter(".autosize").not(".autosize-inited").each(function(){
        autosize($(this));
        $(this).addClass("autosize-inited");
    });
}

function toggler_init() {

    $(document).on("click", ".toggler-js-off", function(e){
        e.preventDefault();
        $(this).closest(".toggler-js").removeClass("active").find(".toggler-js-input").val(0);
        var $o1 = $($(this).data("selector"));
        var $o2 = $($(this).closest(".toggler-js").find(".toggler-js-on").data("selector"));
        if ($o1.length)
        {
            $o1.removeClass("inactive").addClass("active").trigger("activate-it");
            if ($o1.attr("id"))
            {
                location_hash_update("#"+$o1.attr("id"));
            }
        }
        if ($o2.length)
        {
            $o2.addClass("inactive").removeClass("active").trigger("deactivate-it");
        }
    });

    $(document).on("click", ".toggler-js-on", function(e){
        e.preventDefault();
        $(this).closest(".toggler-js").addClass("active").find(".toggler-js-input").val(1);
        var $o1 = $($(this).data("selector"));
        var $o2 = $($(this).closest(".toggler-js").find(".toggler-js-off").data("selector"));
        if ($o1.length)
        {
            $o1.removeClass("inactive").addClass("active").trigger("activate-it");
            if ($o1.attr("id"))
            {
                location_hash_update("#"+$o1.attr("id"));
            }
        }
        if ($o2.length)
        {
            $o2.addClass("inactive").removeClass("active").trigger("deactivate-it");
        }
    });

    $(document).on("click", ".toggler-js-toggle", function(e){
        e.preventDefault();
        var $o = $(this).closest(".toggler-js");
        $o.toggleClass("active").find(".toggler-js-input").val($o.hasClass("active")?1:0);
        var $o1 = $($(this).closest(".toggler-js").find(".toggler-js-on").data("selector"));
        var $o2 = $($(this).closest(".toggler-js").find(".toggler-js-off").data("selector"));
        var on = $o.hasClass("active");
        if ($o1.length)
        {
            $o1.toggleClass("inactive", !on).toggleClass("active", on).trigger(on?"activate-it":"deactivate-it");
            if ($o1.attr("id") && on)
            {
                location_hash_update("#"+$o1.attr("id"));
            }
        }
        if ($o2.length)
        {
            $o2.toggleClass("inactive", on).toggleClass("active", !on).trigger(!on?"activate-it":"deactivate-it");
            if ($o2.attr("id") && !on)
            {
                location_hash_update("#"+$o2.attr("id"));
            }
        }
    });
}


/*

Custom Checkboxes

 */

function checkbox_plain_init() {
    $(".checkbox-plain-js").find("[type='checkbox'], [type='radio']").not(".checkbox-plain-js-inited").on("change change-pseudo", function(e){
        var $o = $(this).closest(".checkbox-plain-js");
        if ($(this).is(":radio"))
        {
            var name = $(this).attr("name");
            var $radios = $(this).closest("form").find("[name='"+name+"']");
            if (!$radios.length) $radios = $("input[name='"+name+"']");
            $radios.closest(".checkbox-plain-js").removeClass("checked");
        }
        if ($(this).prop("checked")) $o.addClass("checked");
        else $o.removeClass("checked");
        if ($(this).prop("disabled")) $o.addClass("disabled");
        else $o.removeClass("disabled");
    }).on("focus", function(e){
        var $o = $(this).closest(".checkbox-plain-js");
        if (!$(this).prop("disabled")) $o.addClass("focused");
    }).on("blur", function(e){
        var $o = $(this).closest(".checkbox-plain-js");
        $o.removeClass("focused");
    }).each(function(){
        var $o = $(this).closest(".checkbox-plain-js");
        if ($(this).prop("checked")) $o.addClass("checked");
        if ($(this).prop("disabled")) $o.addClass("disabled");
    }).addClass("checkbox-plain-js-inited").closest(".checkbox-plain-js").addClass("checkbox-plain-js-inited").find("input:text").on("focus", function(){
        $(this).closest(".checkbox-plain-js").find(":checkbox,:radio").prop("checked", true).trigger("change");
    });
}
