
/*

 Click and touch events helpers

 */

function click_touch_init() {

    $(document).on("click.touchfocused", ".touchevents .touch-focused", function (e) {
        var $o = $(this);
        if (!$o.hasClass("focused")) {
            if (!$o.hasClass("disable-prevent-link-clicks")) {
                e.preventDefault();
            }
            $(".touch-focused").not($o).not($o.closest(".touch-focused")).removeClass("focused");
            $o.addClass("focused");
        }
    });

    $(document).on("click", ".touchevents .touch-focused a", function (e) {
        var $tf = $(this).closest(".touch-focused");
        if (!$tf.hasClass("focused") && !$tf.hasClass("disable-prevent-link-clicks")) {
            e.preventDefault();
        }
    });

    $(document).on("click touchstart", ".touchevents *", function (e) {
        if (!$(e.target).closest(".touch-focused").length) {
            $(".touch-focused").removeClass("focused");
        }
    });

    $(document).on("click", ".no-click", function (e) {
        e.preventDefault();
    });

}



/*

Responsive design helpers

 */

function responsive_type() {
    if (!$(".respon-meter").length)
    {
        $("body").append("<div class='respon-meter'></div>");
    }
    return $(".respon-meter").css("z-index");
}

function responsive_init() {
    responsive_update();
    responsive_type();
}

function responsive_update() {
    $("body").addClass('notransition');
    var header_height = $(".header").outerHeight();
    var footer_height = $(".footer").outerHeight();
    $(".header-replace").height(header_height);
    $(".footer-replace").height(footer_height);

    if (typeof force === "undefined") force = false;
    if ($("body").data("responsive_type") != responsive_type() || force)
    {
        $("body").data("responsive_type", responsive_type());
    }
    if ($("body").data("viewport_width") != $(window).width() || force)
    {
        $("body").data("viewport_width", $(window).width());

        $("[data-place]").each(function(){
            var places = $(this).data("place");
            var breakpoints = Object.keys(places).map(function(value) {
                return parseInt(value);
            }).sort(function(a,b) {
                return a - b;
            }).reverse();
            for (i in breakpoints) {
                if (responsive_type() > breakpoints[i]) {
                    if ($(places[breakpoints[i]]).length)  {
                        $(this).attr("data-place-breakpoint", breakpoints[i]).insertBefore(places[breakpoints[i]]);
                    }
                    break;
                }
            }
        });
    }

    $("body")[0].offsetHeight;
    $("body").removeClass('notransition');
}



/*

Expanded Blocks Functionality

 */

function expand_it_init_prepare($c, force) {
    if (typeof $c === "undefined" || !$c) $c = $(".expand-it-container");
    if (!force) {
        $c = $c.not(".expand-it-container-prepared");
    }
    $c.each(function(){
        var $o = $(this);
        var id = $o.attr("id");
        if (!id)
        {
            id = "id"+(new Date()).getTime() + $o.text().length;
            $o.attr("id", id);
        }
        height = $o.find(".expand-it-inner").outerHeight(true);
        addCSSRule("rule-"+id, "#"+id+".active { max-height: "+ height+"px; }");
        $o.addClass("expand-it-container-prepared");
    });
}

function expand_it_init()
{

    expand_it_init_prepare();
    $(document).ajaxStop(function () {
        expand_it_init_prepare();
    });
    $(window).on("resize", function () {
        expand_it_init_prepare(null, true);
    });

    $(document).on("click expand-it", ".expand-it", function(e){
        e.preventDefault();
        var $o = $($(this).data("expand-selector"));
        if (!$o.length)
        {
            $o = $(this).closest(".expand-it-wrapper").find(".expand-it-container");
        }
        if (!$o.length)
        {
            $o = $($(this).attr("href"));
        }
        if (!$o.length) return;

        var id = $o.attr("id");
        if (!id)
        {
            id = "id"+(new Date()).getTime() + $o.text().length;
            $o.attr("id", id);
        }
        height = $o.find(".expand-it-inner").outerHeight(true);
        $o[0].offsetHeight;
        $o.addClass("notransition");
        $o[0].offsetHeight;
        if (!$o.hasClass("expand-it-container-overflow-hidden"))
        {
            $o.removeClass("overflow-visible");
        }
        $o[0].offsetHeight;
        $o.removeClass("notransition");
        if (!$o.hasClass("active") && !$("#rule-"+id).length) {
            addCSSRule("rule-"+id, "#"+id+".active { max-height: "+ height+"px; }");
        }
        $o[0].offsetHeight;

        if ($(this).attr("data-label")) {
            var label = $(this).html();
            $(this).html($(this).attr("data-label"));
            $(this).attr("data-label", label);
        }
        $(this).toggleClass("active");
        $(".expand-it.active[href='#"+$o.attr("id")+"']").not($(this)).toggleClass("active");

        var $wrapper = $o.closest(".expand-it-wrapper");

        if (e.type !== "expand-it")
        {
            $o.addClass("before-transition").toggleClass("active").siblings(".expand-it-container").each(function(){
                $(".expand-it.active[href='#"+$(this).attr("id")+"']").trigger("expand-it");
                $(this).addClass("before-transition").removeClass("active");
            });
            var is_active = $o.hasClass("active");
            $wrapper.toggleClass("active", is_active);
            if ($wrapper.hasClass("expand-it-wrapper-collapse-siblings"))
            {
                $wrapper.siblings(".expand-it-wrapper").each(function(){
                    var $this = $(this).find(".expand-it-container");
                    if ($(this).find(".expand-it").length)
                    {
                        $(this).find(".expand-it.active").trigger("expand-it");
                    }
                    else
                    {
                        $(".expand-it.active[href='#"+$this.attr("id")+"']").trigger("expand-it");
                    }
                    $this.addClass("before-transition").removeClass("active");
                    $(this).removeClass("active");
                });
                if ($wrapper.hasClass("active")) {
                    setTimeout(function(){
                        goto_object($wrapper);
                    }, 300);
                }
            }
            if ($(this).hasClass("expand-it-hash-change"))
            {
                if (is_active)
                {
                    if ($(this).attr("href"))
                    {
                        location_hash_update($(this).attr("href"));
                    }
                    else if ($wrapper.attr("id"))
                    {
                        location_hash_update("#" + $wrapper.attr("id"));
                    }
                }
                else
                {
                    var $tabpane = $(this).closest(".tab-pane");
                    if ($tabpane.length && $tabpane.attr("id"))
                    {
                        location_hash_update("#"+$tabpane.attr("id"));
                    }
                    else
                    {
                        location_hash_remove();
                    }
                }
            }
        }
    });

    $(document).on("-webkit-transitionend transitionend", ".expand-it-container", function(e){
        if ($(e.target).hasClass("expand-it-container") && !$(e.target).hasClass("expand-it-container-overflow-hidden") && $(e.target).hasClass("before-transition"))
        {
            var height_default = 0;
            if ($(this).find(".expand-it-container-height-default").length)
            {
                height_default = $(this).find(".expand-it-container-height-default").height();
            }
            if (parseInt($(this).css("max-height"), 10) > height_default)
            {
                $(this).addClass("overflow-visible");
            }
            else
            {
                $(this).removeClass("overflow-visible");
            }
        }
        if ($(e.target).hasClass("expand-it-container") && $(e.target).hasClass("expand-it-container-max-height-auto") && $(e.target).hasClass("before-transition"))
        {
            var id = $(this).attr("id");
            setTimeout(function(){
                console.log("removeCSSRule");
                removeCSSRule("rule-"+id);
            }, 300);
        }
        $(e.target).removeClass("before-transition");
    });

    if (location.hash)
    {
        if ($(location.hash).filter(".expand-it-wrapper").length)
        {
            var $o = $(location.hash);
            var $loc_link = $(".expand-it[href='"+location.hash+"']");
            if (!$loc_link.length)
            {
                $loc_link = $o.filter(".expand-it-wrapper").find(".expand-it");
            }
            if ($loc_link.not(".active").filter(":visible").length)
            {
                setTimeout(function(){
                    $loc_link.trigger("click");
                }, 300)
            }
        }
    }
}


/*

Set Background Image depending on img content inside it

 */

function img_to_bg($o) {
    if (typeof $o === "undefined") $o = $(".img-to-bg");
    $o.each(function () {
        if ($(this).find("> img").length && $(this).find("> img").attr("src")) {
            $(this).css("background-image", "url('" + $(this).find("> img").attr("src") + "')");
            $(this).addClass("img-to-bg--inited");
        }
    });
}


/*

 Lazy Loading Helpers

 */

function lazy_load() {
    $("[data-lazy-src]").not(".lazy-load-inited").each(function () {
        if ($(this).offset().top - $(window).height() < $(window).scrollTop() + $(window).height()) {
            lazy_load_change($(this));
        }
    });
}

function lazy_load_change($img) {
    $img.addClass("lazy-load-inited");
    $img.attr("src", $img.attr("data-lazy-src"));
    $img.one("load", function () {
        var $this = $(this);
        img_to_bg($this.closest(".img-to-bg"));
    });
}


/*

Scroll to needed objects

 */

function goto_init()
{
    $(document).on("click", ".js-goto", function (e) {
        var $o = $($(this).attr("href"));
        if ($o.length) {
            e.preventDefault();
            var $this = $(this);
            if ($this.closest(".goto-list").length)
            {
                $this.closest(".goto-list").find("li").removeClass("active");
                $this.closest("li").addClass("active");
                tabs_update_pointer($this.closest(".tabs"));
            }
            if ($this.closest(".goto-hash-change").length)
            {
                location_hash_update($this.attr("href"));
            }
            goto_object($o);
        }
    });
}

function goto_object($o)
{
    if ($o.length)
    {
        $("body").addClass("scroll-animated");
        var offset = $o.offset().top;
        offset -= $(".header").outerHeight();
        $("html, body").stop(true, true).animate({scrollTop: offset}, 500, function(){
            $("body").removeClass("scroll-animated");
        });
    }
}

function activate_goto_link($obj)
{
    $obj.addClass("active").siblings().removeClass("active")
        .end().parent("li").addClass("active").siblings().removeClass("active");
    $obj.trigger("click-tabs-fixed-center");
}


/*

Actions on Scroll

 */

function scroll_animations_init() {
    scroll_animations();
}

function scroll_animations() {

    var st = $(window).scrollTop();

    body_scrolled();
}

function body_scrolled() {
    var scrolled_condition = $(window).scrollTop() > 0;
    if (scrolled_condition) {
        if (!$("body").hasClass("scrolled")) $("body").addClass("scrolled");
    }
    else {
        if ($("body").hasClass("scrolled")) $("body").removeClass("scrolled");
    }

    var scrolled_condition = $(window).scrollTop() > $(".header").outerHeight();
    if (scrolled_condition) {
        if (!$("body").hasClass("mscrolled")) $("body").addClass("mscrolled");
    }
    else {
        if ($("body").hasClass("mscrolled")) $("body").removeClass("mscrolled");
    }
}