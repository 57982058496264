




/*

 Form Validation

 */

function validate_init() {

    if (!$("body").hasClass("validate-inited")) {

        $(document).on("reset", ".form-validate", function() {
            $(this).find(".form__item, :input").removeClass("error");
            setTimeout(function() {
                $(":input").trigger("change");
                $(".checkbox-plain-js input[type='checkbox'], .checkbox-plain-js input[type='radio']").trigger("change-pseudo");
            }, 50);
        })

        $.validator.setDefaults({
            onkeyup: false,
            normalizer: function(value) {
                return $.trim(value);
            },
            errorClass: 'form__error',
            validClass: 'valid',
            errorElement: 'div',
            highlight: function(element, errorClass, validClass) {
                $(element).addClass('error').removeClass('valid');
            },
            unhighlight: function(element, errorClass, validClass) {
                $(element).addClass('valid').removeClass('error');
            },
            errorPlacement: function($error, $element) {
                var elementName = $element.attr("name"),
                    lastInGroup = $.map(this.groups, function(fields, name) {
                        var fieldsArr;
                        if (fields.indexOf(elementName) >= 0) {
                            fieldsArr = fields.split(" ");
                            return fieldsArr[fieldsArr.length - 1];
                        } else {
                            return null;
                        }
                    })[0];
                if (lastInGroup) {
                    $("#"+ lastInGroup).closest(".form__item-field").append($error);
                } else {
                    $element.closest(".form__item-field").append($error);
                }
                //$element.closest(".form__item-field").append($error);
            },
            submitHandler: function(form, e) {
                $(form).trigger("submit.valid");
            },
            // debug: true // DANGER. disables form submission
        });

        $.extend($.validator.messages, {
            required: "Required field.",
            email: "Invalid E-mail.",
        });

        $.each($.validator.methods, function(key, value) {
            $.validator.methods[key] = function() {
                if (arguments.length > 0) {
                    arguments[0] = $.trim(arguments[0]);
                }
                return value.apply(this, arguments);
            };
        });

        $(document).on("keyup blur change check-form", ".form-disabled-until-valid :input", function() {
            var $form = $(this).closest(".form-disabled-until-valid");
            var $button = $form.find(".disabled-until-valid").not(".submitted");

            /*
            * BUG
            *
            * When `email` is checked via `remote`, the `$form.valid()` is called and then
            * the fields that are either empty or invalid are highlighted with `error`
            *
            * Possible solutions:
            * - Skip `checkForm()` → allows to submit empty form
            * - Let it be → when email got checked and REJECTED all the invalid inputs
            * will be highlighted no matter the previous its highlight state.
            *
            * */
            $button.prop('disabled', !$form.validate().checkForm());
        });

        $(document).on("change change-checkbox-valid", "input[type='checkbox'], input[type='radio'], select", function() {
            var $form = $(this).closest("form");
            var validator = $form.data("validator");
            if (validator) $(this).valid();
        });

        $("body").addClass("validate-inited");
    }

    var $forms = $(".form-validate").not(".form-validate-inited");
    var validator = $forms.validate();

    $forms.addClass("form-validate-inited");

    // $forms.find(".disabled-until-valid").prop('disabled', 'true');
    $(".form-disabled-until-valid").find(":input").first().trigger("check-form");

    $(".form-disabled-until-valid").submit(function() {
        $(".disabled-until-valid").prop('disabled', 'disabled');
        $(".disabled-until-valid").addClass("submitted");
        setTimeout(function() {
            $(".disabled-until-valid").prop('disabled',false);
            $(".disabled-until-valid").removeClass("submitted");
        },5000);
    });

}